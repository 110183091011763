import React, {useState, useEffect} from "react";
import { motion } from "framer-motion";
import Highcharts from "highcharts";
import Switch from "react-switch";

// Components
import Input from "./Input";
import Edit from "./Edit";
import Export from "./Export";
import Copy from "./Copy";
import Download from "./Download";
import Envelope from "./Envelope";
import Highchart from "./Highchart";
import CollectiveReport from "./CollectiveReport"
import IllustrationStar from "./IllustrationStar"
import IllustrationFolderDouble from "./IllustrationFolderDouble";
import Gear from "./Gear";

//Helpers
import highchartResize from "../helpers/highchartResize";
const TITLES = {"completed": "Completed", "in_progress": "In progress", "invited": "Invited", "pending": "Pending"};
const COLORS = {"completed": "#B4CEED", "in_progress" :"#78AEDF", "invited": "#788594", "pending": "#585C60"}

const FolderInfo = (props) => {

  highchartResize(Highcharts);

  return (
    <>
      <div className="c-folder-info">
        <div className="c-folder-info__wrapper">
          <div className="c-folder-info__data">
            <div className="c-folder-info__data-title o-margin--bottom-size-l">
            { props.canManage &&
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="c-folder-info__data-icon"
                onClick={() => props.editFolder()}
              >
                <Edit />
              </motion.div>              
            }
              <h1 className="c-folder-info__data-title-text">
                {props.data && props.data.name}
              </h1>
            </div>
            {props.data && props.data.description && (
              <div className="c-folder-info__data-description o-margin--bottom-size-l">
                <p className="c-folder-info__data-description-label">
                  Description:
                </p>
                <p className="c-folder-info__data-description-text">
                  {props.data.description}
                </p>
              </div>
            )}

            <div className="c-folder-info__data-assessments">
              <h5 className="c-folder-info__data-assessments-title">Assessments:</h5>
              {props.data && props.data.survey !== "" &&  (
                  <p className='o-text--link-text'>{props.data && JSON.stringify(props.data.survey)}</p>
              )}
            </div>

            {props.data && props.data.survey !== "" && (
              <React.Fragment>
                <div className="c-folder-info__button o-margin--bottom-size-xl">
                  <div className="c-button__wrapper c-button__wrapper-width-auto c-button__wrapper-has-separation">
                    {/*<button
                      key={"buttons-folder-"}
                      type="button"
                      className={
                        "c-button c-button--margin-zero c-button--cancel"
                      }
                      onClick={() => props.startSurvey()}
                    >
                      <span className="c-button__text">Start survey</span>
                    </button>*/}
                    {
                      !props.has_procedures &&
                        <button
                          type="button"
                          className="c-button c-button--margin-zero"
                          onClick={() => {
                            props.goToEmails()
                          }}
                        >
                          <div className="c-button__icon">
                            <Envelope width="20px" height="30px" />
                          </div>
                          <span className="c-button__text">Emails</span>
                        </button>
                    }
                    <button
                      type="button"
                      className="c-button c-button--margin-zero"
                      onClick={() => {
                        props.openDownloadReportsModal();
                      }}
                    >
                      <div className="c-button__icon">
                        <Download width="20px" height="30px" />
                      </div>
                      <span className="c-button__text">Download reports</span>
                    </button>
                    <button
                      type="button"
                      className="c-button c-button--margin-zero"
                      onClick={() => {
                        props.openExportModal();
                      }}
                    >
                      <div className="c-button__icon">
                        <Export width="20px" height="30px" />
                      </div>
                      <span className="c-button__text">Export</span>
                    </button>
                    { props.canManage == true &&
                        <button
                          type="button"
                          className="c-button c-button--margin-zero"
                          onClick={() => {
                             props.openCopyFolder();
                          }}
                        >
                          <div className="c-button__icon">
                            <IllustrationFolderDouble width={24} height={20}/>
                          </div>
                          <span className="c-button__text">Copy assessment</span>
                        </button>
                    }

                    {
                      props.collective_report_enabled == true &&
                      <button
                        type="button"
                        className="c-button c-button--margin-zero"
                        onClick={() => {
                          props.openCollectiveReportsModal();
                        }}
                      >
                        <div className="c-button__icon">
                          <CollectiveReport width="20px" height="20px" />
                        </div>
                        <span className="c-button__text">Collective report</span>
                      </button>
                    }
                    {
                      props.axis_ranking_enabled == true &&
                      <button
                        type="button"
                        className="c-button c-button--margin-zero"
                        onClick={() => {
                          props.openRankingReportsModal();
                          document.body.style.overflow = 'hidden'
                        }}
                      >
                        <div className="c-button__icon">
                          <IllustrationStar className="c-rating-star__svg" borderColor="white" color="black" width={18} height={17}/>
                        </div>
                        <span className="c-button__text">Ranking</span>
                      </button>
                    }
                  </div>
                </div>
                <h6 className="o-display--flex"><Gear width="24px" height="23px" className="o-margin--right-size-s"/>Config:</h6>
                <div className="c-folder-info__button c-switch__wrapper">
                  {
                    props.data && props.data.switch_configuration && Object.keys(props.data.switch_configuration).map((key, index) => {
                      return (
                        <div className="c-switch__container" key={props.data.switch_configuration[key].title}>
                          <label className="c-switch__label">{props.data.switch_configuration[key].title}</label>
                          <Switch
                            onChange={(checked) => {
                              props.switchConfig(key)
                            }}
                            checked={props.data.switch_configuration[key].value}
                            className={`c-switch c-switch-${index}`}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            onColor="#1dd1a1"
                            offColor="#121a22"
                          />
                        </div>
                      )
                    })
                  }
                </div>
            </React.Fragment>
            )}
          </div>
        </div>
        <div className="c-folder-info__wrapper">
          {props.chart &&
            props.chart.length > 0 &&
            props.chart.map((item, index) => {
              return (
                <Highchart
                  totalUsers={props.totalUsers}
                  classNames={"c-charts__chart--full-width"}
                  title={item.title}
                  data={[
                    {
                      showInLegend: false,
                      data:
                        item.graph_type === "spline"
                          ? Object.values(item.data)
                          : item.data,
                      color:
                        item.graph_type === "spline"
                          ? item.colors[index]
                          : item.colors,
                    },
                  ]}
                  categories={item.categories}
                  type={item.graph_type}
                />
              );
            })}
        </div>
        <h3 className="o-margin--bottom-size-l">Info users status</h3>
            <div className="c-total-users o-margin--bottom-size-xxl">
              
              {props.data && Object.keys(props.data.totals).length > 0 &&
                Object.keys(props.data.totals).map((item, index) => {
                  return (
                    <div key={`total-users-${index}`} className="c-total-users__box">
                      <p className="c-total-users__box-title">
                        <span className="c-total-users__box-point" style={{backgroundColor: COLORS[item]}}></span>
                        {TITLES[item]}
                        </p>
                      <p className="c-total-users__box-info">
                        {props.data.totals[item]}
                      </p>
                    </div>
                  );
                })}
            </div>
      </div>
    </>
  );
};

export default FolderInfo;
