import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Highcharts from "highcharts";
import Switch from "react-switch";

// Components
import Edit from "../../../components/Edit";
import Highchart from "../../../components/Highchart";
import Arrow from "../../../components/Arrow";
import CopyClipboard from "../../../components/CopyClipboard";

//Helpers
import highchartResize from "../../../helpers/highchartResize";

const ProcedureInfo = (props) => {

  highchartResize(Highcharts);

  const copyToClipboard = () => {
    var copyText = document.getElementById("url_text");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand("copy");
  }



  const handleCopyClipboard = (e) => {
    showClipboardTooltip(e)
    navigator.clipboard.writeText(props.data.invitation_token)
  }

  const showClipboardTooltip = (e) => {
    let button = e.currentTarget
    button.classList.add('is-clicked')
    setTimeout(() => {
      button.classList.remove('is-clicked')
    }, 2000)
  }
  
  return (
    <>
      <div className="c-folder-info">
        <div className="">
          <div className="c-folder-info__data">
            <div className="c-folder-info__data-title o-margin--bottom-size-l">
              {
                props.canManage == true &&
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="c-folder-info__data-icon"
                    onClick={() => props.editProcedure()}
                  >
                    <Edit />
                  </motion.div>
                
              }
              <h1 className="c-folder-info__data-title-text">
                {props.data && props.data.title}
              </h1>
            </div>
            {props.data && props.data.description && (
              <div className="c-folder-info__data-description o-margin--bottom-size-l">
                <p className="c-folder-info__data-description-label">
                  Description:
                </p>
                <p className="c-folder-info__data-description-text">
                  {props.data.description}
                </p>
              </div>
            )}
            <div className='c-folder-info__data-date'>
              {
                props.data && props.data.start_date_beautifull &&
                  <div className='c-folder-info__data-date-text'>
                    <h6 className='c-folder-info__data-date-text-title'>Start Date:</h6> <span>{props.data.start_date_beautifull}</span>
                  </div>
              }
              {
                props.data && props.data.end_date_beautifull &&
                  <div className='c-folder-info__data-date-text'>
                    <h6 className='c-folder-info__data-date-text-title'>End Date:</h6> <span>{props.data.end_date_beautifull}</span>
                  </div>
              }
              {
                props.data && props.data.invitation_token !== "" && props.data.public &&
                  <div className='c-folder-info__data-date-text'>
                    <h6 className='c-folder-info__data-date-text-title'>Public link:</h6> 
                    <a
                      className="o-text--link"
                      href={props.data.invitation_token} 
                      target="_blank"
                      title="ir"
                    >
                      <div className='o-text--link-arrow'>
                        <Arrow width={18} height={14}/>
                      </div>
                    </a>                    
                    <button 
                      title='copiar'
                      data-alt="¡Link copiado!"
                      type='button' 
                      onClick={(e)=> handleCopyClipboard(e)} 
                      className='c-copy-clipboard'
                    >
                      <CopyClipboard />
                    </button>
                  </div>
              }
            </div>


            <div className="c-folder-info__data-assessments">
              <h5 className="c-folder-info__data-assessments-title">Assessments:</h5>
              {props.data && props.data.folders && props.data.folders.map((folder, index) => {
                return (
                  <div className="c-folder-info__data-assessments-content">
                    <a 
                      className="o-text--link o-margin--right-size-l" 
                      href={`/admin/organizations/${props.organization_id}/surveys/${folder.id}`} 
                      target="_blank"
                    >
                      <p className='o-text--link-text'>{folder.name}</p>
                    </a>
                    <div className="c-folder-info__data-assessments-content-switches">
                      <div className="c-switch__container o-margin--bottom-zero" key={props.data.switch_configuration[""+folder.id]["admin_report"].title}>
                        <label className="c-switch__label">{props.data.switch_configuration[""+folder.id]["admin_report"].title}</label>
                        <Switch
                          key={"switch_"+index+"_"+props.data.switch_configuration[""+folder.id]["admin_report"].value}
                          onChange={(checked) => {
                            props.switchConfig("admin_report", folder.id)
                          }}
                          checked={props.data.switch_configuration[""+folder.id]["admin_report"].value}
                          className={`c-switch c-switch-${0}`}
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onColor="#1dd1a1"
                          offColor="#121a22"
                        />
                      </div>
                      <div className="c-switch__container o-margin--bottom-zero" key={props.data.switch_configuration[""+folder.id]["report_participant"].title}>
                        <label className="c-switch__label">{props.data.switch_configuration[""+folder.id]["report_participant"].title}</label>
                        <Switch
                          key={"switch_2"+index+"_"+props.data.switch_configuration[""+folder.id]["admin_report"].value}
                          onChange={(checked) => {
                            props.switchConfig("report_participant", folder.id)
                          }}
                          checked={props.data.switch_configuration[""+folder.id]["report_participant"].value}
                          className={`c-switch c-switch-${0}`}
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onColor="#1dd1a1"
                          offColor="#121a22"
                        />
                      </div>
                    </div>

                </div>
                )
              })
              }
            </div>

          </div>
        </div>
        {/*<div className="c-folder-info__wrapper">
          {props.chart &&
            props.chart.length > 0 &&
            props.chart.map((item, index) => {
              return (
                <Highchart
                  totalUsers={props.totalUsers}
                  classNames={"c-charts__chart--full-width"}
                  title={item.title}
                  data={[
                    {
                      showInLegend: false,
                      data:
                        item.graph_type === "spline"
                          ? Object.values(item.data)
                          : item.data,
                      color:
                        item.graph_type === "spline"
                          ? item.colors[index]
                          : item.colors,
                    },
                  ]}
                  categories={item.categories}
                  type={item.graph_type}
                />
              );
            })}
        </div>*/}
      </div>
    </>
  );
};

export default ProcedureInfo;
