import React, { useState, useEffect } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import { motion } from "framer-motion";
import classNames from "classnames";

// Images
import imageDefault from "../../../../../assets/images/test_module_default.jpg";
import en from "../../../../../assets/images/flags/en.png";
import es from "../../../../../assets/images/flags/es.png";
import fr from "../../../../../assets/images/flags/fr.png";
import de from "../../../../../assets/images/flags/de.png";
import cn from "../../../../../assets/images/flags/cn.png";
import hr from "../../../../../assets/images/flags/hr.png";
import pt from "../../../../../assets/images/flags/pt.png";
import it from "../../../../../assets/images/flags/it.png";

const flagPaths = {
  en: en,
  es: es,
  fr: fr,
  de: de,
  cn: cn,
  hr: hr,
  pt: pt,
  it: it
}

// Components
import Checkbox from "../../../components/Checkbox";
import Button from "../../../components/Button";
import Close from "../../../components/Close";
import Link from "../../../components/Link";
import Download from "../../../components/Download";
import Eye from "../../../components/Eye";

const SurveyItem = (props) => {
  const _progressText = (progress) => {
    if (props.ended) {
      return props.internalization.survey.closed;
    } else if (progress == 0) {
      return "";
    } else if (progress > 0 && progress < 100) {
      return props.internalization.survey.in_progress;
    } else if (progress == null && props.external_service === "criteria") {
      return props.internalization.survey.in_progress;
    } else {
      return props.is_module === true
        ? props.internalization.survey.finished
        : props.internalization.survey.finished_a;
    }
  };

  const [titleClasses, setTitleClasses] = useState("c-test__title");
  const [languageSelected, setLanguageSelected] = useState(props.current_user_folder_language || -1);
  const [surveyData, setSurveyData] = useState({title: props.title, description: props.description})

  useEffect(() => {
    setTitleClasses(
      classNames(
        "c-test__title",
        surveyData.title && surveyData.title.length >= 155 && "c-test__title--extra-small",
        surveyData.title && surveyData.title.length >= 55 && "c-test__title--small",
        props.closed && "o-color--grey-medium",
      ),
    );

  }, [surveyData.title]);

  const showReport = (report_url, type) => {
    window.open(`${report_url}${type}`, "_blank");
  };

  const successChangeLanguage = (result) => {
    setSurveyData(result)
  }
  console.log("ppp", props)
  return (
    <React.Fragment>
      <motion.div
        initial={{ opacity: 0, x: -72 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0 }}
        className="c-test__content"
      >

            {props.enabled 
            ? "" 
            : !props.closed 
              ? (<div className="c-test__content-disabled"><h5 className="c-test__status">Tienes que acabar primero la anterior prueba</h5></div>) 
              : ""
          }


        <div className="c-test__image-wrapper">
          <img
            src={props.image_url ? props.image_url : imageDefault}
            className={props.closed ? "c-test__image c-test__image--disabled" : "c-test__image"}
          />
          {props.progress == 100 && <Checkbox big={true} />}
          {props.ended && props.progress !== 100 && (
            <Close color="#76b0eb" width={100} height={100} />
          )}
        </div>

        <div className="c-test__data">

          <h5 className={props.closed ? "c-test__status o-color--info" : "c-test__status"}>
            {_progressText(props.progress)}
          </h5>

          <div className="c-test__texts">
            <h3 className={titleClasses}>{surveyData.title}</h3>
            {props.ellipsis && (
              <LinesEllipsis
                text={surveyData.description}
                maxLine="3"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            )}
            {!props.ellipsis && (
              <p
                className={
                  props.closed ? "c-test__description o-color--grey-medium" : "c-test__description"
                }
                dangerouslySetInnerHTML={{
                  __html: props.closed
                    ? props.internalization.survey.finished_thx
                    : surveyData.description,
                }}
              ></p>
            )}
            {!props.closed && props.external_service === "default" && (
              <h6 className="c-test__progress">
                {props.internalization.survey.progress}: <span>{props.progress} %</span>
              </h6>
            )}
            {!props.closed && props.external_service === "criteria" && (
              <h6 className="c-test__progress">{props.survey_settings.status}</h6>
            )}
            {
              props.is_module !== true && props.enabled_languages && props.enabled_languages.length > 1 &&
              <div className="c-test__languages">
              <h6 className="c-test__languages-title">{props.internalization.survey.languages}:</h6>

              {props.enabled_languages.map((language, index) => (
                <button
                  className={`c-test__languages-flag ${languageSelected === language && "active"}`}
                  type="button"
                  data-title={language}
                  onClick={() => {setLanguageSelected(language); props.setUserFolderLanguageOption(language, successChangeLanguage)} }
                >
                  <img src={flagPaths[language]} alt={language} />
                </button>
              ))}

            </div>
            }
            
          </div>
        </div>
      </motion.div>
      {!props.closed && props.enabled && (
        <div className="c-button__wrapper">
          <Button
            name="start"
            className="c-button"
            type="button"
            text={props.internalization.buttons.start}
            onClick={props.handleClick}
            disabled={props.closed}
            icon={props.external_service === "criteria" ? <Link /> : ""}
          />
        </div>
      )}

      {props.closed && props.report_url && props.report_url !== "" && (
        <div className="c-button__wrapper">
          <Button
            name="start"
            className="c-button o-margin--right-size-l c-button--info"
            type="button"
            text={props.internalization.survey.show_report}
            icon={<Eye />}
            onClick={() => {
              showReport(props.report_url, ".html");
            }}
          />
          <Button
            name="start"
            className="c-button c-button--info"
            type="button"
            text={props.internalization.survey.download_report}
            icon={<Download />}
            onClick={() => {
              showReport(props.report_url, ".pdf");
            }}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default SurveyItem;
