import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {config} from '../../../config'

const enviroment = process.env.FORCE_NODE_ENV || 'development';

let api_config = config[enviroment]

// Actions
import { fetchCreateScore } from "../actions";
import { initCamera, initRecord, sendRecord, initScreen, initScreenRecord, sendScreenRecord, stopCamera, stopRecording } from "../../../lib/proctoring";
import { fetchLogout } from "../../../containers/login/actions";

// Components
import ArrowButton from "../../../components/ArrowButton";
import Arrow from "../../../components/Arrow";
import ProgressBar from "../../../components/ProgressBar";
import SurveyQuestion from "./SurveyQuestion";
import SurveyTimer from "./SurveyTimer";
import Proctoring from "../../../containers/proctoring/Proctoring";
import Spinner from "../../../components/Spinner";
import Modal from "../../../components/Modal";
let timeoutProctoring = null;

const SurveyQuestionsContainer = (props) => {  
  const [question, setQuestion] = useState({});
  const [progress, setProgress] = useState(0);
  const [time, setTime] = useState(0);
  const [timeLeftToNotice, setTimeLeftToNotice] = useState(props.timeLeftToNotice || 180);
  const [answer, setAnswer] = useState({});
  const [step, setStep] = useState(-1);
  const [showModalTimer, setShowModalTimer] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [onboardingProctoringFinished, setOnboardingProctoringFinished] = useState(false);
  const [proctoringConfigSuccess, setProctoringConfigSuccess] = useState(false)
  const [apiDataIdentifier, setApiDataIdentifier] = useState("")
  const proctoring_format = {
    "disabled": 0,
    "webcam": 1,
    "screen_recording": 2,
    "webcam_screen_recording": 3
  }

  useEffect(() => {
    props.fetchCreateScore(props.survey_token, {module_id: props.module_id, question_index: -1, answer: {}, plus: false }, (response) => _success(response), () => {_errorSubmitting()});

    return () => {
      window.camera && stopCamera(window.camera)
      window.screen_record && stopRecording(window.screen_record)
      window.camera = undefined
      window.screen_record = undefined
    };
  },[]);  

  useEffect(() => {
    $(window).scrollTop(0);

    if (step !== -1) {
      if(checkProctoringForQuestion(question) && onboardingProctoringFinished){
        startProctoring()
      }else{        
        window.camera && stopCamera(window.camera)
        window.screen_record && stopRecording(window.screen_record)
      }
    }
    setSubmitting(false)
  }, [step])

  const _submit = (data, current_question, plus) => {
    setSubmitting(true)
    if(checkProctoringForQuestion(current_question)){
      _sendFiles(current_question, props.survey, step, true, data, plus)
    }
    else{
      props.fetchCreateScore(props.survey_token, { module_id: props.module_id, question_index: step, answer: data, plus: plus }, (response) => _success(response), () => {_errorSubmitting()});
    }    
  };

  const trackError = async (data) => {
    const response = await fetch('/api/tracking_events', {
      method: 'POST',
      body: JSON.stringify({"tracking_event": data }),     
      headers: {
        'Content-Type': 'application/json',
        'Accept': "application/json",
        'Authorization': props.token
      }
    });
    const myJson = await response.json(); //extract JSON from the http response
    // do something with myJson
  }

  const _errorSubmitting = () => {
    setSubmitting(false)
  }

  const _success = (response) => {
    if (response.progress === 100) {
      props.onFinished();
      setSubmitting(false)
    } else {  
      if(props.internalization.getLanguage() !== response.user_language){
        props.internalization.setLanguage(response.user_language)  
      }      
      setProgress(response.progress);
      setTime(response.time);
      setAnswer(response.answer);
      setQuestion(response.question);
      setStep(response.step);
      setApiDataIdentifier(response.api_data_identifier)
    }  
  }

  const _sendFiles = (current_question, current_survey, step, keep_recording, data, plus) => {
    let url = `${api_config.apiStorage}/proctorings?ownerable_type=User&api_data_identifier=${apiDataIdentifier}&question_identifier=${current_question.identifier ? current_question.identifier : "not_set"}&step=${step}`
    let promises = []
    if(window.recorderCamera !== undefined) {     
      let cameraPromise = new Promise((resolve, reject) => { 
        window.recorderCamera.stopRecording(() => {sendRecord(window.recorderCamera, url, keep_recording, () => resolve("camera"), () => reject("camera"))});
      });
      promises.push(cameraPromise)
    }

    if(window.recorderScreen !== undefined) {
      let screenPromise = new Promise((resolve, reject) => { 
        window.recorderScreen.stopRecording(() => {sendScreenRecord(window.recorderScreen, url, keep_recording, () => resolve("screen_record"), () => reject("screen_record"))});
      });
      promises.push(screenPromise)          
    }

    // props.fetchCreateScore(props.survey_token, { module_id: props.module_id, question_index: step, answer: data, plus: plus }, (response) => _success(response), () => {});
    Promise.all(promises).then(promises => {
      props.fetchCreateScore(props.survey_token, { module_id: props.module_id, question_index: step, answer: data, plus: plus }, (response) => _success(response), () => {_errorSubmitting()});   
    }).catch(e => {
      console.log(e)
      props.trackError && props.trackError({name: "Proctoring Error Sending", "error_value": e.toString(), type: "sendFiles"})
    });    

  };

  const _errorRecording = () => {
    alert("Error en proctoring")
  }

  const _errorLogin = () => {
    props.fetchLogout();
    props.history.replace("/");
  }

  const _isCameraRecording = (proctoring_format_value) => {
    return [proctoring_format["webcam_screen_recording"], proctoring_format["webcam"]].includes(proctoring_format_value)
  };

  const _isScreenRecording = (proctoring_format_value) => {
    return [proctoring_format["webcam_screen_recording"], proctoring_format["screen_recording"]].includes(proctoring_format_value)
  };

  const startProctoring = () => {
    let promises = []
    if(window.camera == undefined && _isCameraRecording(question.proctoring_format)){
      let cameraPromise = new Promise((resolve, reject) => {          
        initCamera((camera) => { 
            window.camera = camera
            window.recorderCamera = initRecord(window.camera);
            camera.addEventListener('ended', function() {
                setProctoringConfigSuccess(false)
            }, false);
            camera.addEventListener('inactive', function() {
                setProctoringConfigSuccess(false)
            }, false);
            camera.getTracks().forEach(function(track) {
              track.addEventListener('ended', function() {
                   setProctoringConfigSuccess(false)
              }, false);
              track.addEventListener('inactive', function() {
                  setProctoringConfigSuccess(false)
              }, false);
            });                
            resolve('camera')
          }, () => {
            reject("camera")
          });
        });
      promises.push(cameraPromise)
    }
    if(window.screen_record == undefined && _isScreenRecording(question.proctoring_format)){
      let screenPromise = new Promise((resolve, reject) => {
        initScreen((screen) => { 
          window.screen_record = screen
          window.recorderScreen = initScreenRecord(window.screen_record)
          screen.addEventListener('ended', function() {
              setProctoringConfigSuccess(false)
          }, false);
          screen.addEventListener('inactive', function() {
              setProctoringConfigSuccess(false)
          }, false);
          screen.getTracks().forEach(function(track) {
            track.addEventListener('ended', function() {
                setProctoringConfigSuccess(false)
            }, false);
            track.addEventListener('inactive', function() {
                setProctoringConfigSuccess(false)
            }, false);
          });
          resolve('screen')
        }, () => {
          reject("screen")
        });
      });  
      promises.push(screenPromise)
    }

    Promise.all(promises).then(promises => {
      setProctoringConfigSuccess(true)  
    }).catch(e => {
      setProctoringConfigSuccess(false)  
    });
  }

  const _next = (data, current_question) => {
    if (_validate(data, current_question)) _submit(data, current_question, true);
  };

  const _prev = (data, current_question) => {
    _submit(data, current_question, false);
  }

  const _validate = (data, current_question) => {
    if(current_question.requested){
      if(current_question.timeable && time == 0){
        return true
      }
      if(current_question.item_format === 0) {
        return Object.keys(data).length > 0 && Object.keys(data.items).length === 1        
      } else if (current_question.item_format === 1 && current_question.item_format_style !== 14 && current_question.item_format_style !== 15) {
        return Object.keys(data).length > 0 && Object.keys(data.items).length == Object.keys(current_question.items).length;
      } else if (current_question.item_format_style === 14) {
        return Object.keys(data).length > 0 && Object.keys(data.items).length == Object.keys(current_question.first_column).length;
      } else if (current_question.item_format_style === 15) {
        return Object.keys(data).length > 0 && Object.keys(data.items).length == Object.keys(current_question.items).length;
      }
      else {
        return true
      }
    } else {
      return true
    }
  };

  const checkProctoring = () => {
    let cuestion_proctoring_format = question.proctoring_format
    return question.proctoring_format ? (cuestion_proctoring_format && cuestion_proctoring_format !== proctoring_format["disabled"] && proctoringConfigSuccess) : true
  }

  const checkProctoringForQuestion = (current_question) => {
    let cuestion_proctoring_format = current_question.hasOwnProperty("proctoring_format");
    return cuestion_proctoring_format ? (current_question.proctoring_format && current_question.proctoring_format !== proctoring_format["disabled"]) : false
  }

  const onBoardingProctoringSuccess = () => {
    setOnboardingProctoringFinished(true)
    setProctoringConfigSuccess(true)
    window.camera = undefined
    window.screen_record = undefined
    startProctoring() 
    clearTimeout(timeoutProctoring);
    // if(question.item_format_style === 9){
    timeoutProctoring = setInterval(function(){ 
      let url = `${api_config.apiStorage}/proctorings?ownerable_type=User&api_data_identifier=${apiDataIdentifier}&question_identifier=${question.identifier ? question.identifier : "not_set"}&step=${step}`
      if(window.recorderCamera !== undefined) {     
        let cameraPromise = new Promise((resolve, reject) => { 
          window.recorderCamera.stopRecording(() => {sendRecord(window.recorderCamera, url, true, () => resolve("camera"), () => reject("camera"))});
        });
      }

      if(window.recorderScreen !== undefined) {
        let screenPromise = new Promise((resolve, reject) => { 
          window.recorderScreen.stopRecording(() => {sendScreenRecord(window.recorderScreen, url, true, () => resolve("screen_record"), () => reject("screen_record"))});
        });
      }
    }, 15000);
    // }
  } 

  const hideTimerSending = (current_question) =>  {
    return checkProctoringForQuestion(current_question) ? submitting : false
  }

  const convertTime = (seconds_params) => {  
    let seconds = parseInt(seconds_params, 10)                      
    let hours   = Math.floor(seconds / 3600)                 
    let minutes = Math.floor((seconds - (hours * 3600)) / 60)
    seconds = seconds - (hours * 3600) - (minutes * 60)  
    if ( !!hours ) {                                         
      if ( !!minutes ) {                                     
        return `${hours}h ${minutes}m ${seconds}s`           
      } else {                                               
        return `${hours}h ${seconds}s`                       
      }                                                      
    }                                                        
    if ( !!minutes ) {                                       
      return `${minutes} minuto(s) y ${seconds} segundo(s)`                       
    }                                                        
    return `${seconds} segundos`                                     
  }

  const isImageIpsative = () => {
    return question.item_format_style === 10
  }

  const showArrows = () => {
    return !isImageIpsative()
  }  

  const setResponse = (response) => {
    setAnswer(response)
    if(isImageIpsative()){
      _next(response, question)
    }
  }
  return (
  <React.Fragment>
  {
    Object.keys(question).length > 0 && 
      <React.Fragment>
        {
          step !== -1 && checkProctoring() && 
          <React.Fragment>
            <SurveyQuestion
              step={step}
              data={question}
              answer={answer}
              disabled={question.timeable && time == 0}
              handleClick={(data) => setResponse(data)}
              internalization={props.internalization}
            />

            <ProgressBar title={props.internalization.survey.progress} progress={progress} className="o-margin--top-size-xxl@md-and-up"/>
            
            <div className="c-button__wrapper">
              {showArrows() && <ArrowButton className="c-arrow-button--reverse" handleClick={() => _prev(answer, question)} disabled={question.back_disabled} /> }
              {
                question.timeable === true && !hideTimerSending(question) &&
                <SurveyTimer timeLeftToNotice={timeLeftToNotice} survey_token={props.survey_token} showModalTimer={(current_time) => setShowModalTimer({current_time: current_time})}  module_id={props.module_id} token={props.token} submitting={submitting} time={time}/>
              }
              {showArrows() && <ArrowButton handleClick={() => _next(answer, question)} disabled={!_validate(answer, question)} />}
            </div>
            
          </React.Fragment>
        }
        {
          step !== -1 && !checkProctoring() && !onboardingProctoringFinished && 
          <React.Fragment> 
            <Proctoring token={props.token} onSurvey={true} onPermissionDenied={() => setProctoringConfigSuccess(false)} onSuccess={() => onBoardingProctoringSuccess()} success_description={props.internalization.proctoring.success.description_inside} success_button={props.internalization.buttons.start} title_title={props.internalization.proctoring.start.title_inside} title_description={props.internalization.proctoring.start.description_inside} onPermissionDenied={() => setProctoringConfigSuccess(false)} onSuccess={() => onBoardingProctoringSuccess()}/>
          </React.Fragment>
        }
        {
          step !== -1 && !proctoringConfigSuccess && onboardingProctoringFinished && checkProctoringForQuestion(question) &&
          <Proctoring token={props.token} onSurvey={true} success_description={props.internalization.proctoring.success.description_inside} success_button={props.internalization.buttons.start} title_title={props.internalization.proctoring.start.title_inside} title_description={props.internalization.proctoring.start.description_inside} onPermissionDenied={() => setProctoringConfigSuccess(false)} onSuccess={() => onBoardingProctoringSuccess()} />

        }
        {
          submitting && <Spinner />
        }
        {
          Object.keys(showModalTimer).length > 0 &&
            <Modal isVisible={true} small>
              <h2 className="o-text-align--left o-margin--bottom-size-l">
                {props.internalization.survey.warning}
              </h2>
              <p className="o-text-align--left o-margin--bottom-size-l">
                {props.internalization.survey.time_left.replace("%time%", convertTime(showModalTimer.current_time))}
              </p>
              <p className="o-text-align--left o-margin--bottom-size-l" dangerouslySetInnerHTML={{__html: props.internalization.survey.remember_button}}>
                
              </p>
              <p className="o-text-align--left o-margin--bottom-size-l">
                <u>{props.internalization.survey.remember_lost}</u>
              </p>
              <div className="c-modal__buttons-wrapper">
                <button
                  type="button"
                  className="c-button c-modal__button"
                  onClick={() => setShowModalTimer({})}
                >
                  <span className="c-button__text">Ok</span>
                </button>
              </div>
            </Modal>
        }

      </React.Fragment>
  }
  </React.Fragment>
  )
};

const mapStateToProps = (state) => {
  return {
    survey: state.survey,
    token: state.client.token,
    user_id: state.client.user_id
  };
};

const mapDispatchToProps = {
  fetchCreateScore,
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyQuestionsContainer);
