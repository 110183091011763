import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import classNames from "classnames";
import moment from 'moment'

// Components
import Checkbox from "../../../components/Checkbox";

import PaginationNew from '../../../components/PaginationNew'

import Eye from "../../../components/Eye";
import Envelope from "../../../components/Envelope";
import Close from "../../../components/Close";
import Chevron from "../../../components/Chevron";

// CustomHook
import { usePaginationNew}  from "../../../hooks/usePaginationNew";

const ProcedureTableReport = (props) => {
  console.log("procedurtablereport", props)
  const [userList, setUserList] = useState([]);
  const [typeOrder, setTypeOrder] = useState("");
  const [modeOrder, setModeOrder] = useState("asc");

   const {
    goToPreviousPage,
    goToNextPage,
    goToFirstPage,
    goToLastPage,
    goToPage,
    paginationManage,
    itemsForPage,
    checkFirst,
    checkLast,
    reset,
  } = usePaginationNew(props.data ? props.data : [], 10, props.total_pages, props.current_page, (page) => {props.toPage(page) }) 

  const _selectAllUsers = () => {
    let allIds = [];
    if (userList.length !== props.ids.length) {
      userList.map((item) =>
        allIds.push({
          id: item.id,
          status: item.status,
        })
      );
    }
    props.getIds(allIds, true);
  };

  const _selectUser = (user) => {
    let allIds = props.ids;
    if (allIds.length > 0) {
      if (
        allIds.find((item) => item.id === user.id) === undefined
      ) {
        allIds = [
          ...props.ids,
          {
            id: user.id,
            status: user.status,
          },
        ];
      } else {
        allIds = props.ids.filter((item) =>item.id !== user.id);
      }
    } else {
      allIds = [
        ...props.ids,
        { id: user.id, status: user.status },
      ];
    }

    props.getIds(allIds, false);
  };

  useEffect(() => {
    setUserList(props.data);
  }, [props.data]);


  const orderItems = (type, mode) => {
    
    reset();
    let _userList = JSON.parse(JSON.stringify(userList));
    let orderList = [];

    if (type == "date"){
      if (mode === "asc") {
      }
      orderList = _userList.sort(function(a, b) {
          return new moment(a["date"], "DD/MM/YYYY hh:mmh").toDate() - new moment(b["date"], "DD/MM/YYYY hh:mmh").toDate();
        });
      if (mode === "desc") {
        orderList = _userList.sort(function(a, b) {
          return new moment(b["date"], "DD/MM/YYYY hh:mmh").toDate() - new moment(a["date"], "DD/MM/YYYY hh:mmh").toDate();
        });
      }
    }else{
      orderList = _userList.sort((a, b) =>
        a[type] > b[type] ? 1 : b[type] > a[type] ? -1 : 0
      );
        if (mode === "desc") {
        orderList = _userList.reverse();
      }
    }
    
    

    

    setTypeOrder(type);
    setModeOrder(mode);
    setUserList(orderList);
  };  

  return (
    <React.Fragment>
      <div
        className="c-table-report o-grid o-grid--five o-margin--bottom-size-giga"
      >
        <div className="c-table-report__column">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center"
          >
            <Checkbox
              key={"check-email-title"}
              text="Email"
              textClassName="c-checkbox__text c-checkbox__text--bold"
              onClick={() => {
                _selectAllUsers();
              }}
              checked={
                userList.length > 0 &&
                props.ids.length &&
                userList.length === props.ids.length
              }
            />
            <div className="c-table-report__cell-buttons-icons">
              <div
                className="c-table-report__cell-buttons-icons-item"
              onClick={() => orderItems("email", "asc")}
              >
                <Chevron
                  orientation={"up"}
                  color={
                    typeOrder === "email" && modeOrder === "asc"
                      ? "#121A22"
                      : "#b2bec3"
                  }
                  width={"15px"}
                  height={"6px"}
                />
              </div>
              <div
                className="c-table-report__cell-buttons-icons-item"
                onClick={() => orderItems("email", "desc")}
              >
                <Chevron
                  orientation={"down"}
                  color={
                    typeOrder === "email" && modeOrder === "desc"
                      ? "#121A22"
                      : "#b2bec3"
                  }
                  width={"15px"}
                  height={"6px"}
                />
              </div>
            </div>
          </motion.div>
          {userList
            .map((item, index) => {
              return (
                <motion.div
                  key={"table-report-email-1-" + item.id + "_" + item.email + props.search + props.filterStatus}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center"
                >
                  <Checkbox
                    key={"check-email-" + index}
                    textClassName={
                        props.allIds.includes(item.id)
                          ? "c-checkbox__text--report"
                          : "c-checkbox__text--report c-checkbox__text--bold c-checkbox__text--blue"
                    }
                    text={item.email}
                    onClick={() => _selectUser(item)}
                    checked={
                      props.ids.find((user) => user.id === item.id
                      )
                        ? true
                        : false
                    }
                  />
                </motion.div>
              );
            })}
        </div>

        <div className="c-table-report__column">
          <div className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center">
            <p className="c-table-report__cell-text c-table-report__cell-text--bold">
              Name
            </p>
          </div>

          {userList
            .map((item, index) => {
              return (
                <motion.div
                  key={"table-report-name-2-" + item.id + "_" +item.email + props.search + props.filterStatus}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center"
                >
                  <p className="c-table-report__cell__item c-table-report__cell-text">{item.name}</p>
                </motion.div>
              );
            })}
        </div>



        <div className="c-table-report__column">
          <div className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center">
            <p className="c-table-report__cell-text c-table-report__cell-text--bold">
              Date
            </p>
            <div className="c-table-report__cell-buttons-icons">
              <div
                className="c-table-report__cell-buttons-icons-item"
                onClick={() => orderItems("date", "asc")}
              >
                <Chevron
                  orientation={"up"}
                  color={
                    typeOrder === "date" && modeOrder === "asc"
                      ? "#121A22"
                      : "#b2bec3"
                  }
                  width={"15px"}
                  height={"6px"}
                />
              </div>
              <div
                className="c-table-report__cell-buttons-icons-item"
                onClick={() => orderItems("date", "desc")}
              >
                <Chevron
                  orientation={"down"}
                  color={
                    typeOrder === "date" && modeOrder === "desc"
                      ? "#121A22"
                      : "#b2bec3"
                  }
                  width={"15px"}
                  height={"6px"}
                />
              </div>
            </div>
          </div>

          {userList
            .map((item, index) => {
              return (
                <motion.div
                  key={"table-report-date-3-" + item.id + "_" +item.email + props.search + props.filterStatus}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center"
                >
                  <p className="c-table-report__cell__item">{item.date}</p>
                </motion.div>
              );
            })}
        </div>
        <div className="c-table-report__column">
          <div className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center">
            <p className="c-table-report__cell-text c-table-report__cell-text--bold">
              Status
            </p>
            <div className="c-table-report__cell-buttons-icons">
              <div
                className="c-table-report__cell-buttons-icons-item"
                onClick={() => orderItems("status", "asc")}
              >
                <Chevron
                  orientation={"up"}
                  color={
                    typeOrder === "status" && modeOrder === "asc"
                      ? "#121A22"
                      : "#b2bec3"
                  }
                  width={"15px"}
                  height={"6px"}
                />
              </div>
              <div
                className="c-table-report__cell-buttons-icons-item"
                onClick={() => orderItems("status", "desc")}
              >
                <Chevron
                  orientation={"down"}
                  color={
                    typeOrder === "status" && modeOrder === "desc"
                      ? "#121A22"
                      : "#b2bec3"
                  }
                  width={"15px"}
                  height={"6px"}
                />
              </div>
            </div>
          </div>
          {userList
            .map((item, index) => {
                return (
                  <motion.div
                    key={"table-report-progress-4-" + item.id + "_" + item.email + props.search + props.filterStatus}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center"
                  >
                    <p className="c-table-report__cell-text c-table-report__cell-text--bold">
                    {item.status_name}
                  </p>
                  </motion.div>
                );
            })}
        </div>
        <div className="c-table-report__column">
          <div className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center ">
            <p className="c-table-report__cell-text c-table-report__cell-text--bold">
              Actions
            </p>
          </div>

          {userList
            .map((item, index) => {
              return (
                <motion.div
                  key={"table-report-date-5-" + item.id + "_" + item.email + props.search + props.filterStatus}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center"
                >
                  <div className="c-table-report__cell-icons">
                    <div
                      className="c-table-report__cell-icons-item"
                      onClick={() =>
                        // props.ids.length === 0 ||
                        //   (props.ids.length === 1 &&
                        //     props.ids[0].userFolderId === item.user_folder_id)
                        //   ? props.openShowUserModal(item)
                        //   : {}
                        item.id == "" ? "" : props.goTo(item.id)
                        
                      }
                      style={item.id == "" ? {opacity: 0.4} : {}}
                    >
                      <Eye slashed={false} />
                    </div>
                  
                    <div
                      className="c-table-report__cell-icons-item"
                      onClick={() =>
                        props.ids.length === 0 ||
                          (props.ids.length === 1 &&
                            props.ids[0].id === item.id)
                          ? props.openInvitedModal(item)
                          : {}
                      }
                    >
                      <Envelope />
                    </div>
                    {props.canManage && (
                      <div
                        className="c-table-report__cell-icons-item"
                        onClick={() => {
                          props.ids.length === 0 ||
                            (props.ids.length === 1 &&
                              props.ids[0].id === item.id)
                            ? props.openDeleteModal(item)
                            : {};
                        }}
                      >
                        <Close color="#fc5c65" />
                      </div>
                    )}
                  </div>
                </motion.div>
              );
            })}
        </div>
      </div>
        <PaginationNew
          paginationManage={paginationManage}
          total_pages={props.total_pages}
          current_page={props.current_page}
          pagination={userList}
          goToPage={(index) => goToPage(index)}
          goToLastPage={() => goToLastPage()}
          goToFirstPage={() => goToFirstPage()}
          goToNextPage={() => goToNextPage()}
          goToPreviousPage={() => goToPreviousPage()}
          itemsForPage={itemsForPage}
          isFirst={() => checkFirst()}
          isLast={() => checkLast()}
        />
      
    </React.Fragment>
  )
};

export default ProcedureTableReport;
